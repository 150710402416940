import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private accessTokenKey: string;
  private refreshTokenKey: string;
  private readonly expirationKey = 'expires_at';

  constructor(
    private localStorageService: LocalStorageService,
    private appSettingsService: AppSettingsService
  ) {
    this.accessTokenKey = this.appSettingsService.getAuthSettings().accessTokenKey;
    this.refreshTokenKey = this.appSettingsService.getAuthSettings().refreshTokenKey;
  }

  getAccessToken(): string {
    return this.localStorageService.getItem(this.accessTokenKey) as string;
  }

  saveAccessToken(token: string) {
    this.localStorageService.setItem(this.accessTokenKey, token);
  }

  getRefreshToken(): string {
    return this.localStorageService.getItem(this.refreshTokenKey) as string;
  }

  saveRefreshToken(token: string) {
    this.localStorageService.setItem(this.refreshTokenKey, token);
  }

  getExpiration(): number {
    return this.localStorageService.getItem(this.expirationKey) as number;
  }

  saveExpiration(timestamp: number) {
    this.localStorageService.setItem(this.expirationKey, timestamp);
  }

  saveTokens(accessToken: string, refreshToken: string, expiresIn: number) {
    this.saveAccessToken(accessToken);
    this.saveRefreshToken(refreshToken);
    this.saveExpiration(expiresIn);
  }

  removeTokens() {
    this.localStorageService.removeItem(this.accessTokenKey);
    this.localStorageService.removeItem(this.refreshTokenKey);
    this.localStorageService.removeItem(this.expirationKey);
  }
}
